import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { ResponsePaymentResult } from '@shared/models/orders/status-model';
import { PaymentMethodType } from '@shared/services/payments/payment-method-type.enum';
import { IConfigService } from '@shared/types/config-service';
import { NewPaymentMethodDto } from '@shared/types/payments/new-payment-method.dto';
import { PaymentProviderEnum } from '@shared/types/payments/payment-provider.enum';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { CardNumberElement } from '@stripe/react-stripe-js';
import { loadStripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { Stripe } from '@stripe/stripe-js/types/stripe-js';
import { PaymentIntentResult } from '@stripe/stripe-js/types/stripe-js/stripe';

@appInjectable()
export class StripeStrategy implements IPaymentProvider {
  static create() {
    return new StripeStrategy();
  }

  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private _stripePromise: Promise<Stripe | null>;
  name: PaymentProviderEnum = PaymentProviderEnum.STRIPE;

  constructor() {
    this._stripePromise = loadStripe(this.configService.paymentProviderKey);
  }

  get instance() {
    return this._stripePromise;
  }

  init(): Promise<any> {
    return Promise.resolve(undefined);
  }

  async createPaymentMethod({
    email,
    stripe,
    elements,
    isTemporary,
  }: any): Promise<NewPaymentMethodDto> {
    const card = elements.getElement(CardNumberElement) as StripeCardNumberElement;
    const paymentMethod = await stripe.createPaymentMethod({
      card,
      billing_details: { email },
      type: 'card',
    });

    return {
      id: paymentMethod?.paymentMethod?.id,
      paymentProvider: PaymentProviderEnum.STRIPE,
      paymentMethod: paymentMethod?.paymentMethod,
      paymentMethodType: PaymentMethodType.CREDIT_CARD,
      isTemporary,
    };
  }

  async handle3DSercure(
    data: ResponsePaymentResult,
    orderId: string,
  ): Promise<PaymentIntentResult> {
    const stripe = (await this._stripePromise) as Stripe;
    return await stripe.handleCardAction(data?.clientSecret || '');
  }

  downloadSDK() {
    return Promise.resolve();
  }
}
