import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { GetRateModel, GetRateDTO } from '@shared/models/rates/get-model';
import { Currency } from '@shared/models/wallets/currency';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { IRatesService } from '@shared/types/rates-service';

@appInjectable()
export class RatesService implements IRatesService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);

  get availableCurrencies(): Currency[] {
    return [Currency.USDT, Currency.AUDT];
  }

  getRates = async (
    currency: string,
    productName: string,
    isBuy: boolean = true,
  ): Promise<GetRateModel> => {
    const baseURL = this.configService.baseUrlRates;
    const { data } = await this.httpClient.get<GetRateDTO>('/Products/price', {
      baseURL,
      params: {
        Currency: currency,
        ProductName: productName,
        IsBuy: isBuy,
      },
    });

    return new GetRateModel(data);
  };
}
