import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { TimeRangeType } from '@shared/enums/time-range-type.enum';
import {
  OrderBalancesListModel,
  OrderBalancesListQuery,
} from '@shared/models/orders/balances-list-model';
import { OrderCreateModel, OrderCreateQuery } from '@shared/models/orders/create-model';
import { OrderGetModel, OrderGetQuery } from '@shared/models/orders/get-model';
import { OrderListModel, OrderListQuery } from '@shared/models/orders/list-model';
import { OrderStatus } from '@shared/models/orders/order-status';
import { OrdersDownloadOptions } from '@shared/models/orders/orders-download-options';
import { OrdersOrderingOptions } from '@shared/models/orders/orders-ordering-options';
import { PaymentType } from '@shared/models/orders/payment-method';
import {
  ReportPortfolioDTO,
  ReportPortfolioModel,
} from '@shared/models/orders/portfolio-reports-list';
import {
  PreciousMetalAvailableModel,
  PreciousMetalAvailableQuery,
} from '@shared/models/orders/precious-metal-available-model';
import { ReportRoiListModel, ReportRoiListQuery } from '@shared/models/orders/reports-list';
import { RoiTimeRangeType } from '@shared/models/orders/reports-roi';
import {
  SpotPriceHistoryDataResponseModel,
  SpotPriceHistoryDataResponseDTO,
} from '@shared/models/orders/spot-price-history-list-model';
import { OrderStatusModel, OrderStatusQuery } from '@shared/models/orders/status-model';
import {
  PagedQuery,
  PagedResponseModel,
  PagedResponseQuery,
} from '@shared/models/pagination/paged-response-model';
import { UserDetailsDTO } from '@shared/models/users/details-model';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListQuery } from '@shared/models/wallets/list-model';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { IOrdersService } from '@shared/types/orders-service';

@appInjectable()
export class OrdersService implements IOrdersService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);
  private baseURL: string;

  constructor() {
    this.baseURL = this.configService.baseUrlOrders;
  }

  getDetails = async (id: OrderListQuery['id']): Promise<OrderGetModel> => {
    const { data: orderItem } = await this.httpClient.get<OrderGetQuery>(`/myOrders/${id}`, {
      baseURL: this.baseURL,
    });
    return new OrderGetModel(orderItem);
  };

  getList = async (
    status: OrderStatus[] | null = null,
    asc = false,
    page = 1,
    perPage?: number,
  ): Promise<PagedResponseModel<OrderListModel[]>> => {
    const statusQuery = status?.map((s) => `Status=${s}`).join('&');

    const response = await this.httpClient.get<PagedResponseQuery<OrderListQuery[]>>(
      `/myOrders${statusQuery ? `?${statusQuery}` : ''}`,
      {
        baseURL: this.baseURL,
        params: {
          OrderBy: OrdersOrderingOptions.OrderDate,
          OrderByAscending: !asc,
          PageNumber: page,
          PageSize: perPage,
        },
      },
    );

    const { data: items, ...rest } = response.data;
    const orders: OrderListModel[] = items.map((item: OrderListQuery) => new OrderListModel(item));
    return new PagedResponseModel<OrderListModel[]>({ data: orders, ...(rest as PagedQuery) });
  };

  getBalancesList = async (): Promise<OrderBalancesListModel[]> => {
    const { data: items } = await this.httpClient.get<OrderBalancesListQuery[]>(
      '/orders/balances',
      { baseURL: this.baseURL },
    );
    return items.map((item: OrderBalancesListQuery) => new OrderBalancesListModel(item));
  };

  createDepositOrder = async (
    walletId: WalletsListQuery['id'],
    amount: number,
    currency: Currency,
  ): Promise<OrderCreateModel> => {
    const rateResult = await this.httpClient.post(
      '/orders',
      {
        paymentMethod: PaymentType.deposit,
        paymentMethodId: walletId,
        gdc: amount,
        currency: currency,
      },
      { baseURL: this.baseURL },
    );

    const order: OrderCreateQuery = rateResult.data as OrderCreateQuery;
    return new OrderCreateModel(order);
  };

  createCardOrder = async (
    cardId: OrderCreateQuery['id'],
    amount: number,
    currency: Currency,
  ): Promise<OrderCreateModel> => {
    const { data: orderItem } = await this.httpClient.post<OrderCreateQuery>(
      '/orders',
      {
        paymentMethod: PaymentType.card,
        paymentMethodId: cardId,
        gdc: amount,
        currency: currency,
      },
      { baseURL: this.baseURL },
    );

    return new OrderCreateModel(orderItem);
  };

  confirmOrder = async (order: OrderCreateModel) => {
    await this.httpClient.put(`/orders/${order.id}/confirm`, {}, { baseURL: this.baseURL });
  };

  getOrderStatus = async (orderId: OrderListQuery['id']): Promise<OrderStatusModel> => {
    const statusResult = await this.httpClient.get(`/orders/status/${orderId}`, {
      baseURL: this.baseURL,
    });
    const status: OrderStatusQuery = statusResult.data as OrderStatusQuery;
    return new OrderStatusModel(status);
  };

  getPreciousMetalAvailability = async (): Promise<PreciousMetalAvailableModel> => {
    const result = await this.httpClient.get(`/PreciousMetal/availability`, {
      baseURL: this.baseURL,
    });
    const data: PreciousMetalAvailableQuery = result.data as PreciousMetalAvailableQuery;
    return new PreciousMetalAvailableModel(data);
  };

  createGDCTransaction = async (
    orderId: OrderListQuery['id'],
    recipientUserId: UserDetailsDTO['id'],
    amount: number,
    savedRecipient: boolean,
  ): Promise<void> => {
    await this.httpClient.post(
      '/Transfers',
      {
        orderId: orderId,
        recipientUserId,
        amount,
        savedRecipient,
      },
      { baseURL: this.baseURL },
    );
  };

  getCSVFile = async (
    type: OrdersDownloadOptions,
    searchString: string,
    pageNumber: number,
    pageSize: number,
    orderByAscending: boolean,
    orderBy: OrdersOrderingOptions,
    status: OrderStatus[] | null,
  ): Promise<Blob> => {
    let startDate, endDate;
    const currentTimestamp = Date.now();
    if (type === OrdersDownloadOptions.YEAR) {
      startDate = new Date(currentTimestamp - 1000 * 60 * 60 * 24 * 365).toISOString();
      endDate = new Date(currentTimestamp).toISOString();
    } else if (type === OrdersDownloadOptions.MONTH) {
      startDate = new Date(currentTimestamp - 1000 * 60 * 60 * 24 * 30).toISOString();
      endDate = new Date(currentTimestamp).toISOString();
    } else {
      throw 'Incorect period format';
    }
    const params = new URLSearchParams({
      SearchString: searchString,
      OrderBy: orderBy,
      OrderByAscending: orderByAscending.toString(),
      PageNumber: pageNumber.toString(),
      PageSize: pageSize.toString(),
      StartDate: startDate,
      EndDate: endDate,
    } as Record<string, string>).toString();
    const statusQuery = status?.map((s) => `Status=${s}`).join('&');

    const { data } = await this.httpClient.post(
      `/myorders/Export?${params}${statusQuery ? `&${statusQuery}` : ''}`,
      {},
      {
        baseURL: this.baseURL,
      },
    );

    return data as Blob;
  };

  getRoiChart = async (
    timeRangeType: RoiTimeRangeType,
    currency: Currency,
  ): Promise<ReportRoiListModel[]> => {
    const { data } = await this.httpClient.get<ReportRoiListQuery[]>('/Reports/roiChart', {
      baseURL: this.baseURL,
      params: {
        Date: new Date().toISOString(),
        TimeRangeType: timeRangeType,
        Currency: currency,
      },
    });
    return data.map((item: ReportRoiListQuery) => new ReportRoiListModel(item));
  };

  getPortfolioChart = async (
    timeRangeType: TimeRangeType,
    fiatCurrency: string,
  ): Promise<ReportPortfolioModel> => {
    const { data } = await this.httpClient.get<ReportPortfolioDTO>('/Reports/portfolioChart', {
      baseURL: this.baseURL,
      params: {
        TimeRangeType: timeRangeType,
        FiatCurrency: fiatCurrency,
      },
    });
    return new ReportPortfolioModel(data);
  };

  getHistoricalSpotPrices = async (
    timeRange: TimeRangeType,
    currency: Currency,
    smartCoinName: string,
  ): Promise<SpotPriceHistoryDataResponseModel> => {
    const { data } = await this.httpClient.get<SpotPriceHistoryDataResponseDTO>(
      '/Reports/historicalSpotPrices',
      {
        baseURL: this.baseURL,
        params: {
          TimeRangeType: timeRange,
          FiatCurrency: currency,
          SmartCoin: smartCoinName,
        },
      },
    );
    return new SpotPriceHistoryDataResponseModel(data);
  };
}
