import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { DeletionRequestResultsEnum } from '@shared/enums/deletion-request-results.enum';
import { CreateContactDTO } from '@shared/models/contacts/create-contact.dto';
import {
  GetContactListDTO,
  GetContactListModel,
} from '@shared/models/contacts/get-contact-model-model';
import { ErrorKeysEnum } from '@shared/models/error/http-error-response';
import { SendSupportMessageDto } from '@shared/models/help-center/send-support-message.dto';
import { AuthSessionModel, AuthSessionQuery } from '@shared/models/users/auth-session-model';
import {
  AuthorizationRequestModel,
  AuthorizationRequestQuery,
} from '@shared/models/users/authorization-request-model';
import { AvatarImageModel, AvatarImageQuery } from '@shared/models/users/avatar-image';
import { UserDetailsModel, UserDetailsDTO, UserSettings } from '@shared/models/users/details-model';
import { EmptyAvatarModel, EmptyAvatarQuery } from '@shared/models/users/empty-avatar';
import {
  KYCCredentialsModel,
  KYCCredentialsQuery,
} from '@shared/models/users/kyc-credentials-model';
import { TermsDocumentModel, TermsDocumentQuery } from '@shared/models/users/terms-documents-model';
import { UserStatus } from '@shared/models/users/user-status';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { IUsersService } from '@shared/types/users-service';

@appInjectable()
export class UsersService implements IUsersService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);
  private baseURL: string;
  private _isActiveMaintenanceMode = false;
  private userStatus: UserStatus;

  get isActiveMaintenanceMode() {
    return this._isActiveMaintenanceMode;
  }

  get isOnboardingPass() {
    return ![
      UserStatus.PreRegistered,
      UserStatus.PinAdded,
      UserStatus.PhoneNumberAdded,
      UserStatus.TypeOfAccountSet,
      UserStatus.DocumentsApproved,
      UserStatus.SystemNameAdded,
    ].includes(this.userStatus);
  }

  get isNeedKYC() {
    return [UserStatus.NotVerified, UserStatus.UnderReview].includes(this.userStatus);
  }

  private setUserStatus(status: UserStatus): void {
    this.userStatus = status;
  }

  constructor() {
    this.baseURL = this.configService.baseUrlUsers;
  }

  getMyUserDetails = async (): Promise<UserDetailsModel> => {
    const { data } = await this.httpClient.get<UserDetailsDTO>(`/Users/mydetails`, {
      baseURL: this.baseURL,
    });
    this.setUserStatus(data.userStatus as UserStatus);
    return new UserDetailsModel(data);
  };

  updateAlterName = async (alterName: string = ''): Promise<UserDetailsModel> => {
    await this.httpClient.put<UserDetailsDTO>(`/Users`, { alterName }, { baseURL: this.baseURL });
    const { data } = await this.httpClient.get<UserDetailsDTO>(`/Users/mydetails`, {
      baseURL: this.baseURL,
    });
    return new UserDetailsModel(data);
  };

  getUserDetails = async (userId: UserDetailsDTO['id']): Promise<UserDetailsModel> => {
    const { data: user } = await this.httpClient.get<UserDetailsDTO>(`/Users/${userId}/details`, {
      baseURL: this.baseURL,
    });
    return new UserDetailsModel(user);
  };

  getKYCCredentials = async (): Promise<KYCCredentialsModel | any> => {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<KYCCredentialsQuery>(
          `/Users/KYCSession`,
          {},
          {
            baseURL: this.baseURL,
          },
        )
        .then((response) => resolve(new KYCCredentialsModel(response.data)))
        .catch((err) => {
          if (err.status === 400) {
            resolve({
              errors: Array.from(err.errors.keys()).map((key) => {
                return {
                  key: key,
                  text: err.errors.get(key),
                };
              }),
            });
          } else {
            reject(err);
          }
        });
    });
  };

  submitKYCCredentials = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(
          `/Users/SubmitKYCSession`,
          {},
          {
            baseURL: this.baseURL,
          },
        )
        .then(() => resolve({ success: true }))
        .catch((err) => {
          if (err.status === 400) {
            resolve({
              errors: Array.from(err.errors.keys()).map((key) => {
                return {
                  key: key,
                  text: err.errors.get(key),
                };
              }),
            });
          } else {
            reject(err);
          }
        });
    });
  };

  async canSendDeletionRequest(): Promise<boolean> {
    const result = await this.httpClient.get<{ canBeDeleted: boolean }>(
      `/Users/canSendDeletionRequest`,
      {
        baseURL: this.baseURL,
      },
    );
    return result.data.canBeDeleted;
  }

  sendDeletionRequest = async (): Promise<DeletionRequestResultsEnum> => {
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(`/Users/deletionRequest`, {}, { baseURL: this.baseURL })
        .then(() => resolve(DeletionRequestResultsEnum.DELETED))
        .catch((error) => {
          if (error && error.hasError(ErrorKeysEnum.User_Cannot_Request_Deletion)) {
            return resolve(DeletionRequestResultsEnum.CANNOT_BE_REMOVED);
          } else if (error && error.hasError(ErrorKeysEnum.User_Can_Be_Deleted_After_Approval)) {
            return resolve(DeletionRequestResultsEnum.AWAITING_APPROVAL);
          } else {
            reject(error);
          }
        });
    });
  };

  getUserSettings = async (settings: UserSettings): Promise<null> => {
    await this.httpClient.patch<any>(
      `/Users/settings`,
      { settings },
      {
        baseURL: this.baseURL,
      },
    );
    return null;
  };

  isEmailExists = async (email: string): Promise<boolean> => {
    try {
      await this.httpClient.post<UserDetailsDTO>(
        `/Users/validate/email`,
        { email },
        { baseURL: this.baseURL },
      );
      return true;
    } catch (ex) {
      return false;
    }
  };

  getTermsDocuments = async (): Promise<Array<TermsDocumentModel>> => {
    const { data } = await this.httpClient.get<Array<TermsDocumentQuery>>(`/TermsDocuments`, {
      baseURL: this.baseURL,
    });
    return data.map((item) => new TermsDocumentModel(item));
  };

  savePinCode = async (pinCode: string): Promise<void> => {
    await this.httpClient.patch<UserDetailsDTO>(
      `/Users/pinCode`,
      { pinCode },
      { baseURL: this.baseURL },
    );
  };

  savePhoneNumber = async (phoneNumber: string): Promise<void> => {
    await this.httpClient.patch<UserDetailsDTO>(
      `/Users/phoneNumber`,
      { phoneNumber },
      { baseURL: this.baseURL },
    );
  };

  saveUserType = async (userType: string): Promise<void> => {
    await this.httpClient.patch<UserDetailsDTO>(
      `/Users/type`,
      { userType },
      { baseURL: this.baseURL },
    );
  };

  saveApprovedDocuments = async (listOfIds: Array<string>): Promise<void> => {
    await this.httpClient.patch<UserDetailsDTO>(
      `/Users/approvedDocuments`,
      { listOfIds },
      { baseURL: this.baseURL },
    );
  };

  saveSystemName = async (systemName: string): Promise<void> => {
    await this.httpClient.patch<UserDetailsDTO>(
      `/Users/systemName`,
      { systemName },
      { baseURL: this.baseURL },
    );
  };

  getAvatarsList = async (): Promise<Array<AvatarImageModel>> => {
    const { data } = await this.httpClient.get<Array<AvatarImageQuery>>(`/Avatars`, {
      baseURL: this.baseURL,
    });
    return data.map((item) => new AvatarImageModel(item));
  };

  getMyEmptyAvatar = async (): Promise<EmptyAvatarModel> => {
    const { data } = await this.httpClient.get<EmptyAvatarQuery>(`/Users/myEmptyAvatar`, {
      baseURL: this.baseURL,
    });
    return new EmptyAvatarModel(data);
  };

  saveAvatar = async (avatarId: string | null): Promise<void> => {
    await this.httpClient.patch<UserDetailsDTO>(
      `/Users/avatar`,
      { avatarId },
      { baseURL: this.baseURL },
    );
  };

  сreateAuthorizationRequest = async (): Promise<AuthorizationRequestModel> => {
    const { data } = await this.httpClient.post<AuthorizationRequestQuery>(
      `/Users/authorization/device`,
      {
        clientId: this.configService.authConfig.ClientId,
      },
      {
        baseURL: this.baseURL,
      },
    );
    return new AuthorizationRequestModel(data);
  };

  getAuthorizationRequest = async (deviceCode: string): Promise<AuthSessionModel> => {
    const { data } = await this.httpClient.get<AuthSessionQuery>(
      `/Users/authorization/device/status?`,
      {
        params: {
          clientId: this.configService.authConfig.ClientId,
          deviceCode: deviceCode,
        },
        baseURL: this.baseURL,
      },
    );
    return new AuthSessionModel(data);
  };

  getMyInvestmentCertificate = async (currency: string, fiatCurrency: string) => {
    const { data } = await this.httpClient.get<string>(
      `/Users/myInvestmentCertificate/${currency}`,
      {
        responseType: 'arraybuffer',
        baseURL: this.baseURL,
        params: {
          fiatCurrency: fiatCurrency,
        },
      },
    );
    return data;
  };

  getContacts = async (): Promise<GetContactListModel[]> => {
    const { data: contacts } = await this.httpClient.get<GetContactListDTO[]>('/Contacts', {
      baseURL: this.baseURL,
    });
    return contacts.map((contact) => new GetContactListModel(contact));
  };

  createContacts = async (data: CreateContactDTO): Promise<boolean> => {
    await this.httpClient.post<void>('/Contacts', data, {
      baseURL: this.baseURL,
    });
    return true;
  };

  sendSupportMessage = async (data: SendSupportMessageDto) => {
    await this.httpClient.post<void>('/HelpCenter/message', data, {
      baseURL: this.baseURL,
    });
  };

  getDocument = async (title: string): Promise<TermsDocumentModel> => {
    const { data } = await this.httpClient.get<TermsDocumentQuery>(`/InfoDocuments/${title}`, {
      baseURL: this.baseURL,
    });
    return new TermsDocumentModel(data);
  };

  fetchMaintenanceModeStatus = async (): Promise<void> => {
    const { data } = await this.httpClient.get<{ isActive: boolean }>(
      `/SystemMonitoringCenter/checkMaintenanceModeStatus`,
      {
        baseURL: this.baseURL,
      },
    );
    this._isActiveMaintenanceMode = data.isActive;
  };
}
