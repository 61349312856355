import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/auth-service';
import { ISecure3DRepositoryService } from '@shared/types/secure-3d-repository-service';

@appInjectable()
export class Secure3DRepositoryService implements ISecure3DRepositoryService {
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private storageFieldName = '3DS';
  private LIFETIME = 10 * 60 * 1000; // 10 minutes

  saveToStorage(orderId: string): void {
    if (!this.authService.userId) return;
    const ordersMap = this.getOrdersFromStorage();
    ordersMap.set(orderId, Date.now());
    this.saveOrdersToStorage(ordersMap);
  }

  removeFromStorage(orderId: string) {
    if (!this.authService.userId) return;
    const ordersMap = this.getOrdersFromStorage();
    ordersMap.delete(orderId);
    this.saveOrdersToStorage(ordersMap);
  }

  isBlocked3DSecure(orderId: string): boolean {
    if (!this.authService.userId) return false;
    const ordersMap = this.getOrdersFromStorage();
    const secure3DTimestamp = ordersMap.get(orderId) || 0;
    return Date.now() - secure3DTimestamp < this.LIFETIME;
  }

  removeOldOrders() {
    if (!this.authService.userId) return;
    const ordersMap = this.getOrdersFromStorage();
    for (const [orderId, timestamp] of ordersMap.entries()) {
      if (Date.now() - timestamp > this.LIFETIME) {
        ordersMap.delete(orderId);
      }
    }
    this.saveOrdersToStorage(ordersMap);
  }

  private generateKey(): string {
    return `${this.storageFieldName}:${this.authService.userId}`;
  }

  private mapToJson(data: Map<string, number>): string {
    return JSON.stringify(Array.from(data.entries()));
  }

  private jsonToMap(json: string): Map<string, number> {
    let map = new Map<string, number>();
    try {
      map = new Map<string, number>(JSON.parse(json));
    } catch (ex) {}
    return map;
  }

  private getOrdersFromStorage() {
    const key = this.generateKey();
    return this.jsonToMap(localStorage.getItem(key) || '');
  }

  private saveOrdersToStorage(data: Map<string, number>) {
    const key = this.generateKey();
    localStorage.setItem(key, this.mapToJson(data));
  }
}
