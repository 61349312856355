import { appInjectable } from '@core/di/utils';
import TransactionsMessage from '@shared/components/new-design/notifications/transactions-message/transactions-message';
import { TransactionMessageType } from '@shared/enums/transaction-message-type';
import { ITransactionsNotificationService } from '@shared/types/transactions-notification-service';
import { Store } from 'react-notifications-component';

@appInjectable()
export class TransactionsNotificationService implements ITransactionsNotificationService {
  showNotification(data: {
    amount: number;
    currency: string;
    status: string;
    type: TransactionMessageType;
    id: string;
  }): void {
    Store.addNotification({
      content: (
        <TransactionsMessage
          status={data.status}
          type={data.type}
          amount={data.amount}
          currency={data.currency}
          id={data.id}
        />
      ),
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
      },
    });
  }
}
