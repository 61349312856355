import { appInject, appInjectable } from '@core/di/utils';
import { BaseService } from '@core/services/base';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { AuthStatus, TokenRefreshStatus } from '@shared/constants/auth';
import { DI_TOKENS } from '@shared/constants/di';
import { AuthAttributeName, AuthGetModel } from '@shared/models/auth/get-model';
import { AuthSessionModel } from '@shared/models/users/auth-session-model';
import { AuthStatusData, IAuthService } from '@shared/types/auth-service';
import { IConfigService } from '@shared/types/config-service';
import { secondsToMilliseconds } from '@shared/utils/time';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import moment from 'moment';

export type DecodedToken = {
  aud?: string;
  auth_time?: number;
  'cognito:username': string;
  email?: string;
  email_verified?: boolean;
  event_id?: string;
  exp?: number;
  iat?: number;
  iss?: string;
  jti?: string;
  origin_jti?: string;
  sub?: string;
  token_use?: string;
};

@appInjectable()
export class AuthService extends BaseService implements IAuthService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);

  private _userInfo: AuthGetModel | null;
  private _cognitoUser: CognitoUser | null;
  private _cognitoSession: CognitoUserSession | null;
  private _tokenRefreshStatus: TokenRefreshStatus;
  private _tokenRefreshWorker: ReturnType<typeof setInterval> | null;

  private _authStatus: AuthStatus;
  private _autologinCreds: { email: string | null; password: string | null } = {
    email: null,
    password: null,
  };

  get email(): string {
    return this._userInfo?.email || '';
  }

  get userId(): string {
    return this._userInfo?.id || '';
  }

  constructor() {
    super();
    this._userInfo = null;
    this._cognitoUser = null;
    this._authStatus = AuthStatus.loggedOut;

    appMakeObservable(this, {
      _userInfo: appObservable,
      _cognitoUser: appObservable,
      _authStatus: appObservable,
    });
  }

  //called on app launch
  async checkAuthorization() {
    this._cognitoUser = await this.getStoredUser();
    this._cognitoSession = await this.getStoredSession();

    if (!this._cognitoUser || !this._cognitoSession) {
      this.signOut();
      return;
    }

    try {
      await this.refreshToken();
    } catch (error) {
      this.signOut();
      return;
    }
    //triggers render
    this._authStatus = AuthStatus.loggedIn;
  }

  private async scheduleTokenRefresh() {
    if (!this._cognitoSession) throw new Error('No session found');

    const expirationTimestamp = this._cognitoSession.getAccessToken().getExpiration();
    const end = moment.unix(expirationTimestamp);
    const timeLeft = end.diff(moment()) - secondsToMilliseconds(30);

    if (this._tokenRefreshWorker) clearInterval(this._tokenRefreshWorker);
    this._tokenRefreshWorker = setTimeout(() => this.checkAuthorization(), timeLeft);
  }

  get tokens() {
    if (!this._cognitoSession) return { access: '', refresh: '' };

    return {
      access: this._cognitoSession.getAccessToken().getJwtToken(),
      refresh: this._cognitoSession.getRefreshToken().getToken(),
    };
  }

  get tokenRefreshStatus() {
    return this._tokenRefreshStatus;
  }

  refreshToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this._cognitoUser) return reject();
      this._cognitoUser.refreshSession(
        new CognitoRefreshToken({ RefreshToken: this.tokens.refresh }),
        async (err, session) => {
          if (err) return reject();
          this._cognitoSession = session;
          await this.scheduleTokenRefresh();
          await this.fetchUserInfo();
          resolve();
        },
      );
    });
  }

  async authorizeBySessionInfo(sessionData: AuthSessionModel): Promise<void> {
    const decodedToken = jwt_decode(sessionData.asJson.idToken) as DecodedToken;

    this._cognitoUser = new CognitoUser({
      Username: decodedToken['cognito:username'],
      Pool: new CognitoUserPool(this.configService.authConfig),
    });

    this._cognitoSession = new CognitoUserSession({
      AccessToken: new CognitoAccessToken({ AccessToken: sessionData.asJson.accessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: sessionData.asJson.refreshToken }),
      IdToken: new CognitoIdToken({ IdToken: sessionData.asJson.idToken }),
    });
    this._cognitoUser.setSignInUserSession(this._cognitoSession);

    try {
      await this.refreshToken();
    } catch (error) {
      this.signOut();
      return;
    }
    //triggers render
    this._authStatus = AuthStatus.loggedIn;
  }

  get isLoggedIn() {
    return this._authStatus === AuthStatus.loggedIn;
  }

  //Retrive stored user
  private async getStoredUser(): Promise<CognitoUser | null> {
    const userPool = new CognitoUserPool(this.configService.authConfig);
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      return cognitoUser;
    }
    return null;
  }

  private async getStoredSession(): Promise<CognitoUserSession | null> {
    return new Promise((resolve) => {
      if (!this._cognitoUser) return resolve(null);
      this._cognitoUser?.getSession((err: unknown, session: CognitoUserSession) => {
        if (session) {
          resolve(session);
        } else {
          resolve(null);
        }
      });
    });
  }

  //Update user props
  updateUserPhone = (phone: string): Promise<Error | undefined> => {
    return new Promise((resolve, reject) => {
      const phoneAttributes: Array<CognitoUserAttribute> = [
        new CognitoUserAttribute({ Name: 'phone_number', Value: phone }),
      ];
      this._cognitoUser?.updateAttributes(phoneAttributes, (err: Error | undefined) => {
        err ? reject(err) : resolve(undefined);
      });
    });
  };

  sendPhoneVerification = (): Promise<Error | undefined> => {
    return new Promise((resolve, onFailure) => {
      this._cognitoUser?.getAttributeVerificationCode(AuthAttributeName.phone_number, {
        onSuccess: () => resolve(undefined),
        onFailure,
      });
    });
  };

  confirmPhone = (otp: string): Promise<Error | undefined> => {
    return new Promise((resolve, onFailure) => {
      this._cognitoUser?.verifyAttribute(AuthAttributeName.phone_number, otp, {
        onSuccess: async () => {
          await this.getUserInfo(true);
          resolve(undefined);
        },
        onFailure,
      });
    });
  };

  updateMFAPreference = (enabled: boolean): Promise<Error | undefined> => {
    return new Promise((resolve, reject) => {
      const smsMfaSettings = { PreferredMfa: enabled, Enabled: enabled };
      this._cognitoUser?.setUserMfaPreference(smsMfaSettings, null, (err: Error | undefined) => {
        err ? reject(err) : resolve(undefined);
      });
    });
  };

  //Login process
  login = (email: string, password: string): Promise<AuthStatusData> => {
    const userData = { Username: email, Pool: new CognitoUserPool(this.configService.authConfig) };
    const authDetails = new AuthenticationDetails({ Username: email, Password: password });
    this._cognitoUser = new CognitoUser(userData);

    return new Promise((resolve) => {
      this._cognitoUser?.authenticateUser(authDetails, {
        onSuccess: async () => {
          await this.checkAuthorization();
          this._authStatus = AuthStatus.loggedIn;
          resolve({ status: this._authStatus });
        },
        onFailure: (err) => {
          if (err.name === 'UserNotConfirmedException') {
            this._authStatus = AuthStatus.registeredUnconfirmed;
          }
          resolve({ status: this._authStatus, data: err });
        },
        mfaRequired: () => {
          this._authStatus = AuthStatus.mfaRequiredSMS;
          resolve({ status: this._authStatus });
        },
        newPasswordRequired: () => {
          this._authStatus = AuthStatus.passwordChangeRequired;
          resolve({ status: this._authStatus, data: new Error('Password change is required') });
        },
      });
    });
  };

  loginWithOtp = (otp: string): Promise<AuthStatusData> => {
    return new Promise((resolve) => {
      this._cognitoUser?.sendMFACode(otp, {
        onSuccess: async () => {
          await this.checkAuthorization();
          this._authStatus = AuthStatus.loggedIn;
          resolve({ status: this._authStatus });
        },
        onFailure: (err) => {
          resolve({ status: this._authStatus, data: err });
        },
      });
    });
  };

  //Register process
  register = (email: string, password: string): Promise<AuthStatusData> => {
    const userPool = new CognitoUserPool(this.configService.authConfig);
    const attributeEmail = new CognitoUserAttribute({
      Name: 'email',
      Value: email,
    });
    const attributeList = [attributeEmail];
    this._autologinCreds = { email, password };

    return new Promise((resolve) => {
      userPool.signUp(email, password, attributeList, [], (err, result) => {
        if (err) {
          this._authStatus = AuthStatus.loggedOut;
        }
        if (result) {
          this._cognitoUser = result.user;
          this._authStatus = AuthStatus.registeredUnconfirmed;
        } else {
          this._authStatus = AuthStatus.loggedOut;
        }
        resolve({ status: this._authStatus, data: err });
      });
    });
  };

  confirmEmail = (email: string, otp: string): Promise<AuthStatusData> => {
    return new Promise((resolve) => {
      const userData = {
        Username: email,
        Pool: new CognitoUserPool(this.configService.authConfig),
      };
      this._cognitoUser = new CognitoUser(userData);
      this._cognitoUser.confirmRegistration(otp, true, async (err) => {
        if (err) {
          this._authStatus = AuthStatus.registeredUnconfirmed;
        } else if (this._autologinCreds.email && this._autologinCreds.password) {
          //will change _authStatus to loggedIn
          await this.login(this._autologinCreds.email, this._autologinCreds.password);
          this._autologinCreds = { email: null, password: null };
        }
        resolve({ status: this._authStatus, data: err });
      });
    });
  };

  resendEmailConfirmation = (email: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const userData = {
        Username: email,
        Pool: new CognitoUserPool(this.configService.authConfig),
      };
      this._cognitoUser = new CognitoUser(userData);
      this._cognitoUser?.resendConfirmationCode((err) => {
        resolve(!err);
      });
    });
  };

  //Recovery process
  recoverPassword = (email: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const userData = {
        Username: email,
        Pool: new CognitoUserPool(this.configService.authConfig),
      };
      this._cognitoUser = new CognitoUser(userData);
      this._cognitoUser?.forgotPassword({
        onSuccess: () => resolve(true),
        onFailure: () => resolve(false),
      });
    });
  };

  confirmPasswordRecovery = (code: string, password: string): Promise<boolean> => {
    return new Promise((resolve) => {
      this._cognitoUser?.confirmPassword(code, password, {
        onSuccess: () => resolve(true),
        onFailure: () => resolve(false),
      });
    });
  };

  changePassword = (oldPassword: string, newPassword: string): Promise<Error | undefined> => {
    return new Promise((resolve, reject) => {
      this._cognitoUser?.changePassword(oldPassword, newPassword, (err) => {
        if (!err) {
          resolve(undefined);
          return;
        }
        const errorsConfig: Record<string, string> = {
          InvalidParameterException: 'Password must be provided',
          LimitExceededException: 'Too many change password requests. Please try again.',
          NotAuthorizedException: 'Wrong password',
        };
        const convertedError = err as { name?: string };
        const computedError = convertedError.name ? errorsConfig[convertedError.name] : undefined;
        const errorMessage = computedError || 'Something went wrong. Please try again.';
        reject(new Error(errorMessage));
      });
    });
  };

  //Retrive remote user
  getUserInfo = async (fresh: boolean): Promise<AuthGetModel> => {
    if (!this._userInfo || fresh) {
      await this.fetchUserInfo();
    }
    return new Promise((resolve, reject) => {
      this._userInfo ? resolve(this._userInfo) : reject(new Error('User info not found'));
    });
  };

  private fetchUserInfo = (): Promise<AuthGetModel> => {
    return new Promise((resolve, reject) => {
      this._cognitoUser?.getUserAttributes((err, attributes) => {
        if (attributes) {
          const id = attributes.find((a) => a.Name === AuthAttributeName.id)?.getValue();
          const email = attributes.find((a) => a.Name === AuthAttributeName.email)?.getValue();
          const isEmailVerified = attributes
            .find((a) => a.Name === AuthAttributeName.email_verified)
            ?.getValue();
          const phone = attributes
            .find((a) => a.Name === AuthAttributeName.phone_number)
            ?.getValue();
          const isPhoneVerified = attributes
            .find((a) => a.Name === AuthAttributeName.phone_number_verified)
            ?.getValue();
          if (id && email && isEmailVerified !== null) {
            const userInfo = new AuthGetModel({
              id,
              email,
              phone,
              isEmailVerified: isEmailVerified === 'true',
              isPhoneVerified: isPhoneVerified === 'true',
            });
            this._userInfo = userInfo;
            resolve(userInfo);
          }
        }
        reject(err || new Error('Missing required attributes'));
      });
    });
  };

  //Session manipulations

  signOut = () => {
    this._cognitoUser && this._cognitoUser.signOut();
    this._userInfo = null;
    this._authStatus = AuthStatus.loggedOut;
  };
}
