import { appInjectable } from '@core/di/utils';
import SmartCoinMessage from '@shared/components/new-design/notifications/smart-coin-order-message/smart-coin-order-message';
import { ISmartCoinNotificationService } from '@shared/types/smart-coin-notification-service';
import { SmartCoinOrderStatusExternal } from '@shared/types/smart-coin-order-status';
import { Store } from 'react-notifications-component';

@appInjectable()
export class SmartCoinNotificationService implements ISmartCoinNotificationService {
  showNotification(data: {
    status: SmartCoinOrderStatusExternal;
    id: string;
    quantity: string;
    smartCoinName: string;
    isBuy: boolean;
  }): void {
    Store.addNotification({
      content: (
        <SmartCoinMessage
          status={data.status}
          smartCoin={data.smartCoinName}
          quantity={data.quantity}
          id={data.id}
          isBuy={data.isBuy}
        />
      ),
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
      },
    });
  }
}
