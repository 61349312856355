import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { smartCoinOrderStatusPipe } from '@shared/pipes/smart-coin-order-status.pipe';
import { IOrdersSmartCoinService } from '@shared/types/orders-smart-coin-service';
import { ISecure3DRepositoryService } from '@shared/types/secure-3d-repository-service';
import { ISmartCoinNotificationService } from '@shared/types/smart-coin-notification-service';
import {
  SmartCoinOrderStatusExternal,
  SmartCoinOrderStatusInternal,
} from '@shared/types/smart-coin-order-status';
import { ISmartCoinRepositoryService } from '@shared/types/smart-coin-repository-service';
import { ISmartCoinTrackingService } from '@shared/types/smart-coin-tracking-service';
import { formatAmount } from '@shared/utils/metals';

@appInjectable()
export class SmartCoinTrackingService implements ISmartCoinTrackingService {
  private smartCoinNotificationService = appInject<ISmartCoinNotificationService>(
    DI_TOKENS.smartCoinNotificaionService,
  );
  private smartCoinRepositoryService = appInject<ISmartCoinRepositoryService>(
    DI_TOKENS.smartCoinRepositoryService,
  );
  private ordersSmartCoinService = appInject<IOrdersSmartCoinService>(
    DI_TOKENS.ordersSmartCoinService,
  );
  private secure3DRepositoryService = appInject<ISecure3DRepositoryService>(
    DI_TOKENS.secure3DRepositoryService,
  );
  private subscriptions: Map<string, Function> = new Map();
  private subscriptionsExecutedOrder: Map<string, Function> = new Map();
  private shown3DSecureMessages: Map<string, boolean> = new Map();

  init() {
    setInterval(() => {
      this.trackOrders();
    }, 5000);
  }

  async startTracking(data: {
    orderId: string;
    amount: number;
    smartCoin: string;
    isBuy: boolean;
    secure3DAlreadyShown?: boolean;
    hideFirstMessage?: boolean;
  }) {
    this.smartCoinRepositoryService.saveOrderToStorage(
      data.orderId,
      SmartCoinOrderStatusExternal.NEW,
      data.isBuy,
    );

    if (data.secure3DAlreadyShown) {
      this.shown3DSecureMessages.set(data.orderId, true);
    }

    if (!data.hideFirstMessage) {
      this.smartCoinNotificationService.showNotification({
        id: data.orderId,
        status: SmartCoinOrderStatusExternal.IN_PROGRESS,
        quantity: formatAmount(data.amount, 8),
        smartCoinName: data.smartCoin,
        isBuy: data.isBuy,
      });
    }
  }

  stopTracking(orderId: string) {
    this.smartCoinRepositoryService.removeOrderFromStorage(orderId);
    this.secure3DRepositoryService.removeFromStorage(orderId);
  }

  subscribe(subscriptionId: string, cb: () => void) {
    this.subscriptions.set(subscriptionId, cb);
  }

  unsubscribe(subscriptionId: string) {
    this.subscriptions.get(subscriptionId);
  }

  subscribeForExecutedOrder(subscriptionId: string, cb: () => void) {
    this.subscriptionsExecutedOrder.set(subscriptionId, cb);
  }

  unsubscribeForExecutedOrder(subscriptionId: string) {
    this.subscriptions.get(subscriptionId);
  }

  async trackOrders() {
    for (const storedOrderItem of this.smartCoinRepositoryService.getActiveOrdersFromStrorage()) {
      if (!storedOrderItem.id) {
        this.stopTracking(storedOrderItem.id);
      }
      const orderStatus = await this.ordersSmartCoinService.getOrderStatus(storedOrderItem.id);
      const externalStatus = smartCoinOrderStatusPipe(
        orderStatus.asJson.status as SmartCoinOrderStatusInternal,
      );

      if (
        externalStatus === SmartCoinOrderStatusExternal.SECURE_3D &&
        !this.shown3DSecureMessages.has(storedOrderItem.id)
      ) {
        this.shown3DSecureMessages.set(storedOrderItem.id, true);
        this.smartCoinNotificationService.showNotification({
          id: storedOrderItem.id,
          status: externalStatus,
          quantity: formatAmount(orderStatus.asJson.quantity, 8),
          smartCoinName: orderStatus.asJson.smartCoin,
          isBuy: storedOrderItem.isBuy,
        });
      }

      if (storedOrderItem.status !== externalStatus) {
        if (
          externalStatus !== SmartCoinOrderStatusExternal.SECURE_3D ||
          !this.shown3DSecureMessages.has(storedOrderItem.id)
        ) {
          this.smartCoinNotificationService.showNotification({
            id: storedOrderItem.id,
            status: externalStatus,
            quantity: formatAmount(orderStatus.asJson.quantity, 8),
            smartCoinName: orderStatus.asJson.smartCoin,
            isBuy: storedOrderItem.isBuy,
          });
        }
        this.smartCoinRepositoryService.updateOrderStatus(storedOrderItem.id, externalStatus);
        for (const cb of Array.from(this.subscriptions.values())) {
          cb();
        }
      }
      if (
        [SmartCoinOrderStatusExternal.EXECUTED, SmartCoinOrderStatusExternal.CANCELED].includes(
          externalStatus,
        )
      ) {
        this.stopTracking(storedOrderItem.id);
      }

      if (externalStatus === SmartCoinOrderStatusExternal.EXECUTED) {
        for (const cb of Array.from(this.subscriptionsExecutedOrder.values())) {
          cb();
        }
      }
    }
  }
}
