import { appInjectable } from '@core/di/utils';
import { NotificationMessageType } from '@shared/components/new-design/notifications/notification-message/message-type';
import { INotificationService } from '@shared/types/notification-service';
import { Store } from 'react-notifications-component';

import NotificationMessage from 'src/shared/components/new-design/notifications/notification-message';

@appInjectable()
export class NotificationService implements INotificationService {
  showError(text: string): void {
    Store.addNotification({
      content: <NotificationMessage text={text} type={NotificationMessageType.WARNING} />,
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
      },
    });
  }

  showSuccess(text: string, options?: { type?: NotificationMessageType }): void {
    Store.addNotification({
      content: (
        <NotificationMessage text={text} type={options?.type || NotificationMessageType.INFO} />
      ),
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
      },
    });
  }
}
