import { appInjectable } from '@core/di/utils';
import { IErrorMessagesService } from '@shared/types/error-messages-service';

import * as stripeErrors from '../stripe/stripe-errors.json';

@appInjectable()
export class ErrorMessagesService implements IErrorMessagesService {
  private _errors: Map<string, string> = new Map();

  constructor() {
    this.setErrors(stripeErrors);
  }

  setErrors(errors: any) {
    Object.keys(errors).forEach((error) => {
      this._errors.set(error, errors[error]);
    });
  }

  get(error: string): string {
    return this._errors.get(error) || 'An error occurred';
  }
}
