import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { TransactionOperationType } from '@shared/enums/transaction-operation-type.enum';
import { OrderListQuery } from '@shared/models/orders/list-model';
import { PaymentMethod } from '@shared/models/orders/payment-method';
import {
  PagedQuery,
  PagedResponseModel,
  PagedResponseQuery,
} from '@shared/models/pagination/paged-response-model';
import { RequisitesListModel, RequisitesListQuery } from '@shared/models/requisites/list-model';
import { TransactionListModel, TransactionListQuery } from '@shared/models/transactions/list-model';
import { TransactionType } from '@shared/models/transactions/transaction-type';
import {
  TransacrtionDetailsDTO,
  TransacrtionDetailsModel,
} from '@shared/models/transactions/transactions-detail-model';
import { TransferListModel, TransferListQuery } from '@shared/models/transfers/list-model';
import { UserDetailsDTO } from '@shared/models/users/details-model';
import {
  BlockchainWalletModel,
  BlockchainWalletQuery,
} from '@shared/models/wallets/blockchain-wallet';
import { DepositCreateModel, DepositCreateQuery } from '@shared/models/wallets/create-model';
import { Currency } from '@shared/models/wallets/currency';
import { LimitsModel, LimitsDTO } from '@shared/models/wallets/limits-model';
import { WalletsListModel, WalletsListQuery } from '@shared/models/wallets/list-model';
import {
  RecentActivitiesModel,
  RecentActivityQuery,
} from '@shared/models/wallets/recent-activities';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { IWalletsService } from '@shared/types/wallets-service';
import { apiDateFormat } from '@shared/utils/date';

@appInjectable()
export class WalletsService implements IWalletsService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);
  private baseURL: string;

  constructor() {
    this.baseURL = this.configService.baseUrlWallets;
  }

  getBankRequisites = async (): Promise<RequisitesListModel[]> => {
    const { data } = await this.httpClient.get<RequisitesListQuery[]>('/BankRequisites', {
      baseURL: this.baseURL,
    });
    return data.map((item: RequisitesListQuery) => new RequisitesListModel(item));
  };

  getList = async (currency: string, includeFiatValues: boolean = false) => {
    const { data: items } = await this.httpClient.get<WalletsListQuery[]>('/wallets', {
      baseURL: this.baseURL,
      params: {
        fiatCurrency: currency,
        includeFiatValues,
      },
    });
    return items.map((item: WalletsListQuery) => new WalletsListModel(item));
  };

  getWalletById = async (walletId: WalletsListQuery['id']): Promise<WalletsListModel> => {
    const { data } = await this.httpClient.get<WalletsListQuery>(`/wallets/${walletId}`, {
      baseURL: this.baseURL,
    });
    return new WalletsListModel(data);
  };

  getWalletTransactions = async (
    walletId: WalletsListQuery['id'],
    transactionType: TransactionType,
    perPage: number,
    page = 1,
    startDate?: Date | null,
    endDate?: Date | null,
  ): Promise<PagedResponseModel<TransactionListModel[]>> => {
    const response = await this.httpClient.get<PagedResponseQuery<TransactionListQuery[] | null>>(
      `/wallets/${walletId}/transactions`,
      {
        baseURL: this.baseURL,
        params: {
          TransactionType: transactionType,
          PageNumber: page,
          PageSize: perPage,
          StartDate: startDate ? apiDateFormat(startDate) : null,
          EndDate: endDate ? apiDateFormat(endDate) : null,
        },
      },
    );
    const { data: items, ...rest } = response.data;

    const paging = rest as PagedQuery;
    const orders: TransactionListModel[] =
      items?.map((item: TransactionListQuery) => new TransactionListModel(item)) || [];
    return new PagedResponseModel<TransactionListModel[]>({ data: orders, ...paging });
  };

  getDirectTransfers = async (
    currency: Currency,
    perPage: number,
    page: number,
    isSent: boolean,
  ): Promise<PagedResponseModel<TransferListModel[]>> => {
    const response = await this.httpClient.get<PagedResponseQuery<TransferListQuery[]>>(
      '/Transfers',
      {
        baseURL: this.baseURL,
        params: {
          Currency: currency,
          PageNumber: page,
          PageSize: perPage,
          IsSent: isSent,
        },
      },
    );
    const { data: items, ...rest } = response.data;
    const paging = rest as PagedQuery;
    const transfers: TransferListModel[] = items.map(
      (item: TransferListQuery) => new TransferListModel(item),
    );
    return new PagedResponseModel<TransferListModel[]>({ data: transfers, ...paging });
  };

  confirmPaymentTransaction = async (paymentIntendId: string, orderId?: OrderListQuery['id']) => {
    const body = orderId ? { externalTransactionId: orderId } : {};

    await this.httpClient.post(`/Transactions/${paymentIntendId}/confirm`, body, {
      baseURL: this.baseURL,
    });
  };

  declinePaymentTransaction = async (paymentIntendId: string, orderId?: OrderListQuery['id']) => {
    const body = orderId ? { externalTransactionId: orderId } : {};

    await this.httpClient.post(`/Transactions/${paymentIntendId}/decline`, body, {
      baseURL: this.baseURL,
    });
  };

  createDepositWallet = async (
    walletId: WalletsListQuery['id'],
    paymentMethodId: PaymentMethod['value'],
    amount: number,
  ) => {
    const response = await this.httpClient.post(
      `/wallets/${walletId}/deposit`,
      { paymentMethodId, amount },
      { baseURL: this.baseURL },
    );
    const deposit = response.data as DepositCreateQuery;

    return new DepositCreateModel(deposit);
  };

  sellFiatTokens = async (
    walletId: WalletsListQuery['id'],
    paymentMethodId: PaymentMethod['value'],
    amount: number,
  ) => {
    const response = await this.httpClient.post(
      `/wallets/${walletId}/withdraw`,
      { paymentMethodId, amount },
      { baseURL: this.baseURL },
    );
    const deposit = response.data as DepositCreateQuery;

    return new DepositCreateModel(deposit);
  };

  getRecentActivities = async (): Promise<RecentActivitiesModel[]> => {
    const { data } = await this.httpClient.get<RecentActivityQuery[]>(
      `/Transactions/MyTransactions`,
      {
        baseURL: this.baseURL,
      },
    );

    return data.map((item: RecentActivityQuery) => new RecentActivitiesModel(item));
  };

  getLimits = async (): Promise<LimitsModel> => {
    const { data } = await this.httpClient.get<LimitsDTO>(`/Transactions/limits`, {
      baseURL: this.baseURL,
    });
    return new LimitsModel(data);
  };

  getBlockchainWallets = async (): Promise<BlockchainWalletModel> => {
    const { data } = await this.httpClient.get<BlockchainWalletQuery>(`/myBlockchainWallet`, {
      baseURL: this.baseURL,
    });

    return new BlockchainWalletModel(data);
  };

  createGSCTransaction = async (
    recipientUserId: UserDetailsDTO['id'],
    amount: number,
    savedRecipient: boolean,
  ): Promise<void> => {
    await this.httpClient.post(
      '/Transfers',
      {
        recipientUserId,
        amount,
        savedRecipient,
      },
      { baseURL: this.baseURL },
    );
  };

  createP2PTransfer = async (
    recipientUserId: UserDetailsDTO['id'],
    amount: number,
    savedRecipient: boolean,
    currency: string,
  ): Promise<void> => {
    await this.httpClient.post(
      '/Transfers',
      {
        recipientUserId,
        amount,
        savedRecipient,
        currency: currency,
        externalTransactionId: null,
      },
      { baseURL: this.baseURL },
    );
  };

  async getTransactionDetails(id: string): Promise<TransacrtionDetailsModel> {
    const result = await this.httpClient.get<TransacrtionDetailsDTO>(`/transactions/${id}`, {
      baseURL: this.baseURL,
    });
    return new TransacrtionDetailsModel(result.data);
  }

  async getTransactionFee(
    paymentMethodId: string,
    transactionOperationType: TransactionOperationType,
    amount: number,
  ) {
    const { data } = await this.httpClient.get<{ transactionFee: number }>(
      `/myCreditCards/${paymentMethodId}/estimateFee`,
      {
        baseURL: this.baseURL,
        params: {
          transactionOperationType,
          amount,
        },
      },
    );
    return data.transactionFee;
  }
}
