import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { MetalProductSpotPriceModel } from '@shared/models/products/metal-product-spot-price.model';
import { ProductModel, ProductQuery, ProductType } from '@shared/models/products/product-model';
import { ProductPricesDTO, ProductPricesModel } from '@shared/models/products/product-prices.model';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { IProductsService } from '@shared/types/products-service';

@appInjectable()
export class ProductsService implements IProductsService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);
  private baseURL: string;
  private productsList: Array<ProductModel> | null = [];
  private productsMap = new Map<string, ProductModel>();
  private metalProductSpotPrice: Array<MetalProductSpotPriceModel> | null;

  constructor() {
    this.baseURL = this.configService.baseUrlOrders;
  }

  getList = async (includingСache: boolean = false): Promise<Array<ProductModel>> => {
    if (includingСache && this.productsList?.length) return this.productsList;
    const { data } = await this.httpClient.get<Array<ProductQuery>>(`/Products`, {
      baseURL: this.baseURL,
    });
    const products = data.map((item: ProductQuery) => new ProductModel(item));
    this.productsList = products;
    this.productsList.forEach((product) => {
      this.productsMap.set(product.asJson.name, product);
    });
    return products;
  };

  getProductsWithPrices = async (
    fiatCurrency: string,
    options: { includingСache?: boolean } = {},
  ): Promise<Array<MetalProductSpotPriceModel>> => {
    if (options.includingСache && this.metalProductSpotPrice) return this.metalProductSpotPrice;

    const products = await this.getList(true);

    const metalProduct = products.filter(
      (product) => this.getProductTypeByCurrency(product.asJson.name) === ProductType.SMART_COIN,
    );

    const { data } = await this.httpClient.get<ProductPricesDTO[]>(`/Products/prices`, {
      baseURL: this.baseURL,
      params: {
        ProductName: metalProduct.map((product) => product.asJson.name),
        FiatCurrency: fiatCurrency,
      },
      paramsSerializer: (params: any) => {
        return Object.keys(params)
          .filter((key) => params[key] !== undefined)
          .map((key: string) => {
            if (key === 'ProductName') {
              return params[key]
                .map((value: string) => `ProductName=${encodeURIComponent(value)}`)
                .join('&');
            } else {
              return `${key}=${encodeURIComponent(params[key])}`;
            }
          })
          .join('&');
      },
    });
    const productPrices = data.map((productPrice) => new ProductPricesModel(productPrice));

    const metalProductSpotPrices = metalProduct.map((product) => {
      const productPrice = productPrices.find(
        (productPrice) => productPrice.asJson.product === product.asJson.name,
      );
      return new MetalProductSpotPriceModel({
        id: product.asJson.id,
        productName: product.asJson.name,
        metalName: product.asJson.baseCurrency,
        price: productPrice?.asJson.marketPrice || 0,
        priceChangePercent: productPrice?.asJson.dailyChangeInPercent || 0,
        iconUrl: '',
      });
    });
    this.metalProductSpotPrice = metalProductSpotPrices;
    return metalProductSpotPrices;
  };

  get products() {
    return this.productsList || [];
  }

  getProductByCurrency(currency: string): ProductModel | undefined {
    return this.productsMap.get(currency);
  }

  getProductTypeByCurrency(currency: string): string {
    return this.getProductByCurrency(currency)?.asJson.type || '';
  }

  getBaseCurrencyByCurrency(currency: string): string {
    return this.getProductByCurrency(currency)?.asJson.baseCurrency || '';
  }
}
