import { appInject, appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { CreditCardListModel, CreditCardListQuery } from '@shared/models/credit-card/list-model';
import { ICardsService } from '@shared/types/card-service';
import { IConfigService } from '@shared/types/config-service';
import { IHttpClientService } from '@shared/types/http-client';
import { NewPaymentMethodDto } from '@shared/types/payments/new-payment-method.dto';

@appInjectable()
export class CardsService implements ICardsService {
  private configService = appInject<IConfigService>(DI_TOKENS.configService);
  private httpClient = appInject<IHttpClientService>(DI_TOKENS.appHttpClientService);
  private baseURL: string;

  constructor() {
    this.baseURL = this.configService.baseUrlCards;
  }

  getCardList = async (): Promise<CreditCardListModel[]> => {
    const { data: cardItems } = await this.httpClient.get<CreditCardListQuery[]>('/MyCreditCards', {
      baseURL: this.baseURL,
    });

    return cardItems.map((item: CreditCardListQuery) => new CreditCardListModel(item));
  };

  removeCard = async (id: CreditCardListQuery['id']): Promise<void> => {
    await this.httpClient.delete<CreditCardListQuery>(`/MyCreditCards/${id}`, {
      baseURL: this.baseURL,
    });
  };

  saveCard = async (paymentMethod: NewPaymentMethodDto): Promise<CreditCardListModel> => {
    const { data: cardItem } = await this.httpClient.post<CreditCardListQuery>(
      '/MyCreditCards',
      {
        id: paymentMethod.id,
        cardHolder: paymentMethod.cardHolder || undefined,
        paymentProvider: paymentMethod.paymentProvider,
        paymentMethodType: paymentMethod.paymentMethodType,
        isTemporary: paymentMethod.isTemporary,
      },
      { baseURL: this.baseURL },
    );
    return new CreditCardListModel(cardItem);
  };
}
