import { appInjectable } from '@core/di/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { ResponsePaymentResult } from '@shared/models/orders/status-model';
import { IPaymentProvider } from '@shared/types/payments/payment-provider.interface';
import { inject } from 'inversify';

@appInjectable()
export class PaymentsService implements IPaymentProvider {
  private paymentProvider: IPaymentProvider;

  constructor(@inject(DI_TOKENS.worldpayStrategy) paymentProvider: IPaymentProvider) {
    this.paymentProvider = paymentProvider;
  }

  get name() {
    return this.paymentProvider.name;
  }

  get instance() {
    return this.paymentProvider.instance;
  }

  async createPaymentMethod(data: any) {
    return await this.paymentProvider.createPaymentMethod(data);
  }

  async handle3DSercure(data: ResponsePaymentResult, orderId: string): Promise<any> {
    return await this.paymentProvider.handle3DSercure(data, orderId);
  }

  async init(data: any) {
    return await this.paymentProvider.init(data);
  }

  downloadSDK() {
    return this.paymentProvider.downloadSDK();
  }
}
